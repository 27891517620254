<template>
  <div>
    <div class="sign-up" v-if="show && !addShow">
      <div class="title">{{ activityName }}</div>
      <div class="time" v-if="playType == 'LIVE_BROADCAST'">
        开课时间： {{ courseStartTime }}
      </div>
      <div class="time" v-else>报名截止时间：{{ endRegisterTime }}</div>
      <div v-if="isApply">
        <div class="clear around">
          <div class="fl texe">请确认报名信息</div>
          <div class="fr" @click.stop="selectApplyIncident">
            <span class="text">选择报名信息</span>
            <span class="bg"></span>
          </div>
        </div>
        <div class="up">
          <div class="flex conet upBox">
            <div class="left">姓名：</div>
            <span>{{ courseSubmit.teacherName }}</span>
          </div>
          <div class="flex conet upBox">
            <div class="left">手机号：</div>
            <span>{{ courseSubmit.teacherPhone }}</span>
          </div>
          <div class="flex conet upBox">
            <div class="left">地区：</div>
            <span>{{ courseSubmit.province }}/{{ courseSubmit.city }}/{{
                courseSubmit.area
              }}</span
            >
          </div>
          <div class="flex conet upBox">
            <div class="left">工作单位：</div>
            <span>{{ courseSubmit.teacherSchool }}</span>
          </div>
          <div v-for="(itme, index) in registerDeployList" :key="index">
            <div class="flex con" style="margin-bottom: 15px">
              <div class="left">
                <span v-if="itme.isRequire" style="color: #e4393c">*</span>
                {{ index + 1 }}、 {{ itme.title }}：
              </div>
              <template v-if="itme.type == 'SELECT'">
                <div class="rigWihtd">
                  <el-select
                    :placeholder="itme.title"
                    v-model="itme.description"
                  >
                    <el-option
                      style="color: #363636; font-weight: normal"
                      v-for="option in itme.options"
                      :key="option.optionValue"
                      :label="option.hotelName"
                      :value="option.optionValue"
                    >
                    </el-option>
                  </el-select>
                </div>
              </template>
              <template v-if="itme.type == 'TEXT'">
                <template v-if="itme.deployKind == 'EMAIL'">
                  <el-input
                    type="email"
                    placeholder="请输入邮箱"
                    v-model="itme.description"
                  >
                  </el-input>
                </template>
                <template v-else>
                  <el-input
                    type="textarea"
                    maxlength="200"
                    placeholder="请输入"
                    v-model="itme.description"
                  >
                  </el-input>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="playType == 'LIVE_BROADCAST' || playType == 'ONSITE_TEACHING'"
          :class="['inform', checked ? 'active' : '']"
          @click="checkedIncident"
        >
          开课前40分钟接收短信通知
        </div>
        <div class="hint">
          * 提示： 为保障您能更好地获取后续服务，请您确保以上信息真实有效。
        </div>
        <div class="affirm">
          <el-button :loading="loading" @click="applyCourseSubmit"
            >确认</el-button
          >
        </div>
      </div>
      <div v-else-if="!isApply" class="con conet">
        <div class="empty-bg"></div>
        <p class="empty-p">暂无相关报名信息，请先添加报名信息！</p>
        <div class="empty-btn" @click.stop="emptyBtn">添加报名信息</div>
      </div>
    </div>
    <div v-else-if="!show && !addShow" class="apply">
      <h1 class="title">选择报名信息</h1>
      <div class="apply-scroll">
        <div class="apply-box" v-for="(itme, index) in tableData" :key="index">
          <div class="flex conet j-w">
            <div class="flex conet">
              <div class="f-title">{{ itme.name }}</div>
              <div>{{ itme.phone | phoneFilter }}</div>
            </div>
            <div
              :class="['bg', itme.isDefault ? 'active' : '']"
              @click.stop="checkedApplyPitch(itme, index)"
            ></div>
          </div>
          <p>{{ itme.province }}/{{ itme.city }}/{{ itme.area }}</p>
          <p>{{ itme.schoolName }}</p>
        </div>
      </div>
      <div class="affirm" style="margintop: 60px">
        <el-button v-if="tableData.length <= 5">
          <div @click.stop="affirmIncident('add')">添加报名信息</div>
        </el-button>
        <el-button style="background: #e6e6e6; border-color: #e6e6e6" v-else>
          <div @click.stop="affirmIncident('full')">添加报名信息</div>
        </el-button>
        <div style="width: 30px"></div>
        <el-button>
          <div @click.stop="affirmIncident('selection')">确认</div>
        </el-button>
      </div>
    </div>
    <div v-else-if="!show && addShow">
      <add-information
        @informationRegistrationIncident="informationRegistrationIncident"
      ></add-information>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Utils from '@/utils';
import { checkEmail } from '@/utils/verify';
import {
  courseTeacherInfoDefault,
  courseTeacherInfoRegisterDeploy
} from '@/api/api';
import {
  courseTeacherInfo,
  postCourseTeacherInfo,
  courseTeacherInfoNocode
} from '@/api/personal';
import AddInformation from '@/components/addInformation';
import { Input } from 'element-ui';
Vue.use(Input);
export default {
  name: 'SignUp',
  components: {
    AddInformation
  },
  props: {
    playType: {
      type: String,
      default: ''
    },
    endRegisterTime: {
      type: String,
      default: ''
    },
    courseStartTime: {
      type: String,
      default: ''
    },
    activityName: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      show: true,
      addShow: false,
      isApply: false,
      tableData: [],
      registerDeployList: [], // 附加字段后台设置
      courseSubmit: {
        area: '', // 区
        areaId: '', // 区Id
        city: '', // 市
        cityId: '', // 市Id
        province: '', // 省
        provinceId: '', // 省Id
        teacherName: '', // 报名姓名
        teacherPhone: '', // 报名电话
        teacherSchool: '', // 报名学校
        teacherSchoolId: '', // 报名学校编号
        tmCourseTeacherInfoId: '' // 报名信息编号
      },
      conmmDubmit: {
        ip: '', // ip
        activityId: this.activityId, // 活动编号
        wetherNotify: false // 是否开启短信通知
      },
      loading: false
    };
  },
  filters: {
    phoneFilter(val) {
      return val.substring(0, 3) + '****' + val.substring(7, val.length);
    }
  },
  watch: {
    dialogVisible(vol) {
      if (!vol) {
        this.show = true;
        this.addShow = false;
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    // 初始化数据
    init() {
      this.getCourseTeacherInfoDefault();
    },
    getCourseTeacherInfoDefault() {
      courseTeacherInfoDefault({}).then((res) => {
        if (res.code == 200) {
          if (res.data != null) {
            const {
              area,
              areaId,
              city,
              cityId,
              name,
              phone,
              province,
              provinceId,
              schoolId,
              schoolName,
              tbCourseTeacherId
            } = res.data;
            this.courseSubmit = {
              area: area,
              areaId: areaId,
              city: city,
              cityId: cityId,
              province: province,
              provinceId: provinceId,
              teacherName: name,
              teacherPhone: phone,
              teacherSchool: schoolName,
              teacherSchoolId: schoolId,
              tmCourseTeacherInfoId: tbCourseTeacherId
            };
            this.isApply = true;
          } else {
            this.isApply = false;
          }
        }
      });
      courseTeacherInfoRegisterDeploy({
        activityId: this.activityId
      }).then((res) => {
        if (res.code == 200) {
          this.registerDeployList = res.data;
        }
      });
    },
    publicParsing(data) {
      const {
        area,
        areaId,
        city,
        cityId,
        name,
        phone,
        province,
        provinceId,
        schoolId,
        schoolName,
        courseTeacherInfoId
      } = data;
      this.courseSubmit = {
        area: area,
        areaId: areaId,
        city: city,
        cityId: cityId,
        province: province,
        provinceId: provinceId,
        teacherName: name,
        teacherPhone: phone,
        teacherSchool: schoolName,
        teacherSchoolId: schoolId,
        tmCourseTeacherInfoId: courseTeacherInfoId
      };
    },
    // 公共返回
    addComm() {
      this.$loading();
      setTimeout(() => {
        this.$loading().close();
        Utils.msgSuccess('添加成功');
        this.addShow = false;
        this.selectApplyIncident();
      }, 1500);
    },
    informationRegistrationIncident(e) {
      e.obj.isDefault = true;
      if (e.isNeed) {
        // 有验证码添加
        postCourseTeacherInfo(e.obj).then((res) => {
          if (res.code == 200) {
            this.addComm();
          }
        });
      } else {
        // 无验证码添加
        courseTeacherInfoNocode(e.obj).then((res) => {
          if (res.code == 200) {
            this.addComm();
          }
        });
      }
    },
    // 选中是否短信发送
    checkedIncident() {
      this.checked = !this.checked;
    },
    // 确认报名信息
    applyCourseSubmit() {
      var arr = [],
        tbUserRegisterInfos = [],
        vaCheckEmail = false;
      for (let i = 0; i < this.registerDeployList.length; i++) {
        if (this.registerDeployList[i].isRequire) {
          arr.push(this.registerDeployList[i]);
        }
        if (this.registerDeployList[i].description) {
          if (
            this.registerDeployList[i].deployKind === 'EMAIL' &&
            !checkEmail(this.registerDeployList[i].description)
          ) {
            vaCheckEmail = true;
          }
          var objs = {
            activityId: this.activityId,
            deployId: this.registerDeployList[i].tempDeployId,
            type: this.registerDeployList[i].type,
            value: this.registerDeployList[i].description,
            deployKind: this.registerDeployList[i].deployKind
          };
          tbUserRegisterInfos.push(objs);
        }
      }
      // 校验是否操作了必须项}
      if (arr.length > 0) {
        if (tbUserRegisterInfos.length > 0) {
          // 过滤数据
          const set = tbUserRegisterInfos.map((item) => item.deployId);
          arr = arr.filter((item) => !set.includes(item.tempDeployId));
          for (let i = 0; i < arr.length; i++) {
            Utils.msgWarning(arr[i]['title'] + '是必须的');
            return;
          }
        } else {
          Utils.msgWarning(`红色的 * 是选（填）项`);
          return;
        }
      }
      // checkEmail 校验邮箱
      if (vaCheckEmail) {
        Utils.msgWarning(`邮箱格式不正确`);
        return;
      }
      if (
        this.playType == 'LIVE_BROADCAST' ||
        this.playType == 'ONSITE_TEACHING'
      ) {
        this.conmmDubmit.wetherNotify = this.checked;
      }
      this.loading = true;
      // 报名信息
      const obj = {
        tbUserRegisterInfos: tbUserRegisterInfos,
        ...this.conmmDubmit,
        ...this.courseSubmit
      };

      this.$emit('affirmIncidentApply', obj);
    },
    loadingIncident() {
      this.loading = false;
    },
    /**
     * 添加报名/选择信息
     * @method 添加报名/选择信息
     * */
    affirmIncident(method) {
      switch (method) {
        case 'selection':
          this.show = true;
          break;
        case 'add':
          this.addShow = true;
          this.show = false;
          break;
        case 'full':
          Utils.msgWarning('最多只能保留6个报名信息~');
          break;
        default:
          break;
      }
    },
    // 选择报名信息(点击是默认选中)
    selectApplyIncident() {
      this.show = false;
      courseTeacherInfo({
        pageNum: 1,
        pageSize: 10
      }).then((res) => {
        if (res.code == 200) {
          const { list } = res.data;
          this.tableData = list;
          for (let i = 0; i < list.length; i++) {
            if (list[i].isDefault) {
              this.publicParsing(list[i]);
            }
          }
        }
      });
    },
    // 报名信息选中
    checkedApplyPitch(itme, index) {
      this.tableData.forEach((element) => {
        element['isDefault'] = false;
      });
      itme.isDefault = true;
      this.publicParsing(itme);
    },
    // 没有个人报名信息的时候跳转去报名信息
    emptyBtn() {
      const { id } = this.$route.query;
      this.$router.replace({
        path: '/manage',
        query: {
          path: this.$route.path,
          id: id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.sign-up {
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  .title,
  .time {
    text-align: center;
    line-height: 24px;
  }
  .title {
    .fontSize(@TEXT-SIZE-18);
    font-weight: bold;
    margin-bottom: 10px;
  }
  .time {
    color: @TEXT-COLOR-FF5102;
    .fontSize(@TEXT-SIZE-14);
    padding-bottom: 20px;
    .border-direction(bottom,1px,solid,@TEXT-BG-E6E6E6);
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  .around {
    width: 100%;
    .fontSize(@TEXT-SIZE-16);
    margin-bottom: 18px;
    .texe {
      color: @--color-primary;
    }
    .fr {
      line-height: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .text {
        margin-right: 5px;
        color: @--color-primary;
      }
      .bg {
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("~@/assets/images/curriculum/j-t.png") no-repeat 0px 0px;
        vertical-align: middle;
      }
    }
  }
  // 个人信息
  .up {
    box-sizing: border-box;
    padding-bottom: 10px;
    .border-direction(bottom,1px,solid,@TEXT-BG-F4F4F4);
    margin-bottom: 20px;
    .upBox {
      width: 100%;
      height: 40px;
      color: @TEXT-COLOR-888888;
      .fontSize(@TEXT-SIZE-14);
    }
    .left {
      color: @TEXT-COLOR-363636;
      .fontSize(@TEXT-SIZE-16);
      margin-right: 4px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  // 是否通知
  .inform {
    padding-left: 30px;
    box-sizing: border-box;
    line-height: 20px;
    background: url("~@/assets/images/curriculum/xz.png") no-repeat 0px 0px;
    margin-bottom: 18px;
    cursor: pointer;
    .fontSize(@TEXT-SIZE-14);
    &.active {
      background: url("~@/assets/images/curriculum/xz (1).png") no-repeat 0px
        0px;
    }
  }
  .hint {
    .fontSize(@TEXT-SIZE-12);
    color: @TEXT-COLOR-888888;
  }
}
.affirm {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /deep/.el-button {
    width: 180px;
    height: 40px;
    border-radius: 20px;
    background: @--color-primary;
    border: 1px solid @--color-primary;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-FFFFFF !important;
  }
}
// 选择报名信息
.apply {
  box-sizing: border-box;
  .fontSize(@TEXT-SIZE-14);
  color: @TEXT-COLOR-888888;
  .title {
    font-weight: bold;
    text-align: center;
    .fontSize(@TEXT-SIZE-18);
    color: @TEXT-COLOR-363636;
    margin-bottom: 15px;
  }
  .apply-scroll::-webkit-scrollbar {
    display: none;
  }
  .apply-scroll::-moz-scrollbar {
    display: none;
  }
  .apply-scroll::-ms-scrollbar {
    display: none;
  }
  .apply-scroll {
    height: 330px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .apply-box {
    .border-direction(bottom,1px,solid,@TEXT-BG-E6E6E6);
    padding-bottom: 20px;
    padding-top: 20px;
    .f-title {
      font-weight: bold;
      .fontSize(@TEXT-SIZE-18);
      color: @TEXT-COLOR-363636;
      margin-right: 5px;
    }
    .gender {
      margin-left: 15px;
      margin-right: 30px;
    }
    p {
      margin-top: 10px;
    }
    .bg {
      height: 20px;
      background: url("~@/assets/images/home/unselected.png") no-repeat 0px 0px;
      width: 20px;
      cursor: pointer;
      &.active {
        background: url("~@/assets/images/home/pitch-on.png") no-repeat 0px 0px;
      }
    }
  }
}
.empty-bg {
  width: 280px;
  height: 160px;
  background: url("~@/assets/images/curriculum/empty-114.png") no-repeat 0px 0px;
  margin-top: 60px;
  margin-bottom: 20px;
}
.empty-p {
  .fontSize(@TEXT-SIZE-16);
  color: @TEXT-COLOR-888888;
}
.empty-btn {
  margin-top: 30px;
  width: 180px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: @--color-primary;
  .fontSize(@TEXT-SIZE-16);
  color: @TEXT-COLOR-FFFFFF;
  cursor: pointer;
}
.rigWihtd {
  .selectBox(100%,38px,0px);
}
/deep/.el-textarea__inner {
  border: 1px solid #e6e6e6;
  .fontSize(@TEXT-SIZE-14) !important;
  color: @TEXT-COLOR-888888 !important;
  width: 100%;
  height: 80px;
}
/deep/.el-textarea {
  width: 100%;
}
/deep/.el-input__inner:focus,
/deep/.el-textarea__inner:focus {
  border: 1px solid @--color-primary;
}
</style>