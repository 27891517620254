<template>
  <div class="details-directory">
    <div class="flex">
      <TabBox
        :detaIndex="detaIndex"
        :detaList="detaList"
        @detaIncident="detaIncident"
      ></TabBox>
    </div>
    <div v-if="detaIndex == 0" class="describe">
      <p class="text" v-html="activityIntroduce"></p>
    </div>
    <div v-if="detaIndex == 1" class="catalogue">
      <div
        class="catalogue-box clear"
        v-for="(itme, index) in courseCatalogueList"
        :key="index"
      >
        <div v-if="type == 'ONSITE_TEACHING'" class="fl catalogue-div">
          <span class="catalogue-span">{{ index + 1 }}</span>
          {{ itme.courseName }}
        </div>
        <div
          v-else
          :class="[
            'fl',
            'title',
            courseCatalogueIndex == index ? 'active' : '',
          ]"
          @click="courseCatalogueIncident(itme, index)"
        >
          {{ itme.courseName }}
        </div>
        <div v-if="type == 'LIVE_BROADCAST'" class="fr">
          <div class="flex dol">
            <span :class="[itme.courseState == 'RUNNING' ? 'active' : '']">{{
              itme.courseState | course_state
            }}</span>
            <span class="time">开课时间：{{ itme.courseStartTimes }}</span>
            <span class="active">{{ itme.studyHour }} 学时</span>
          </div>
        </div>
        <div v-else-if="type == 'UPLOAD_VIDEO'" class="fr dol clear">
          <div
            class="fl"
            style="color: #e4393c"
            v-if="itme.state == 'NOT_WATCH'"
          >
            未完成
          </div>
          <div class="fl" style="color: #00bd92" v-if="itme.state == 'WATCH'">
            已完成
          </div>
          <div class="fl active" style="margin-left: 20px">
            {{ itme.studyHour }} 学时
          </div>
        </div>
        <div class="fr dol clear" v-else-if="type == 'ONSITE_TEACHING'">
          <div class="fl">上课时间：{{ itme.courseStartTimes }}</div>
          <div class="fl active" style="margin-left: 20px">
            {{ itme.studyHour }} 学时
          </div>
        </div>
      </div>
    </div>
    <div v-if="detaIndex == 2" class="catalogue">
      <div
        class="catalogue-box clear"
        v-for="(itme, index) in courseWareList"
        :key="index"
      >
        <div class="fl catalogue-div">
          <span class="catalogue-span">{{ index + 1 }}</span>
          {{ itme.courseName }}
        </div>

        <div
          class="fr clear download ul-button"
          v-if="consumType && !wetherToRegister"
          @click="download(itme)"
        >
          <div class="fl">{{ itme.courseWareName }}<span></span></div>
          <a class="fl downloads"></a>
        </div>
        <a
          class="fr clear download ul-button"
          v-if="consumType && wetherToRegister"
          :href="`${href}?courseWareUrl=${itme.courseWareUrl}&courseWareName=${itme.courseWareName}`"
          @click="download(itme)"
        >
          <div class="fl">{{ itme.courseWareName }}<span></span></div>
          <a class="fl downloads"></a>
        </a>
        <div class="fr">已下载{{ itme.downloadCount }}次</div>
      </div>
    </div>
  </div>
</template>
<script>
import TabBox from '@/components/TabBox/index';
import { mapGetters } from 'vuex';
import { course_state } from '@/config/statusCode';
import {
  // activityCourseCourseWare,
  courseWareDownload
} from '@/api/api';
import Utils from '@/utils';
import axios from 'axios';
export default {
  name: 'DetailsDirectory',
  filters: { course_state },
  components: {
    TabBox
  },
  props: {
    detaIndex: {
      type: Number,
      default: 0
    },
    activityIntroduce: {
      type: String,
      default: '<p></p>'
    },
    courseCatalogueList: {
      type: Array,
      default: []
    },
    courseWareList: {
      type: Array,
      default: []
    },
    type: {
      type: String,
      default: ''
    },
    wetherToRegister: {
      type: Boolean,
      default: false
    },
    signUpSuccess: {
      type: Boolean,
      default: false
    },
    activityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      detaList: ['课程详情', '课程目录', '下载课件'],
      courseCatalogueIndex: -1,
      href: process.env.VUE_APP_XIAZAI_API
    };
  },
  computed: {
    ...mapGetters(['consumType', 'token'])
  },
  watch: {
    signUpSuccess(vol) {
      if (vol) {
        this.courseCatalogueIndex = 0;
      } else {
        this.courseCatalogueIndex = -1;
      }
    }
  },
  methods: {
    detaIncident(index) {
      this.$emit('detaIncident', index);
    },
    // 选择课程菜单
    courseCatalogueIncident(itme, index) {
      this.$emit('courseCatalogueIncident', itme);
      if (this.wetherToRegister && this.consumType) {
        this.courseCatalogueIndex = index;
      }
    },
    download(e) {
      if (!this.wetherToRegister) {
        Utils.msgWarning('请先报名该活动');
        // this.$emit('signUpImmediatelyIncident')
        return;
      }
      if (e.courseWareUrl == null || e.courseWareUrl == '') {
        Utils.msgWarning('暂不提供课件下载');
        return;
      }
      //   e.loading = true
      //   axios({
      //     method: 'get',
      //     url: '/api' + activityCourseCourseWare(),
      //     responseType: 'blob',
      //     headers: { Authorization: 'hys' + this.token() },
      //     params: {
      //       courseWareUrl: e.courseWareUrl,
      //       courseWareName: e.courseWareName
      //     }
      //   }).then(res => {
      //     console.log(res)
      // var blob = new Blob([res.data],
      // { type: 'application/x-www-form-urlencoded;charset=UTF-8' });
      // var downloadElement = document.createElement('a');
      // var href = window.URL.createObjectURL(blob);
      // downloadElement.href = href;
      // // downloadElement.download = e.courseWareName;
      // document.body.appendChild(downloadElement);
      // downloadElement.click();
      // document.body.removeChild(downloadElement);
      // window.URL.revokeObjectURL(href);
      // e.loading = false
      // // 调用课件下载记录
      courseWareDownload({
        activityId: this.activityId,
        courseWareId: e.id,
        visitType: 'WEB'
      }).then((r) => {});
      // })
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.details-directory {
  box-sizing: border-box;
  padding: 40px 0 60px 0;
  .describe {
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-888888;
    box-sizing: border-box;
    padding-top: 30px;
    overflow: hidden;
    .title {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .text {
      margin-bottom: 40px;
      line-height: 32px;
      width: 100%;
      overflow: hidden;
      /deep/p,
      /deep/div {
        box-sizing: border-box;
        width: 100%;
      }
      /deep/img {
        width: 100%;
      }
    }
  }
  .catalogue {
    margin-top: 20px;
    .fontSize(@TEXT-SIZE-16);
    color: @TEXT-COLOR-888888;
    .catalogue-box {
      padding: 20px;
      box-sizing: border-box;
      .catalogue-div {
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .catalogue-span {
        margin-right: 6px;
      }
      &:hover .title {
        color: @--color-primary;
      }
    }
    .title {
      padding-left: 35px;
      cursor: pointer;
      line-height: 22px;
      background: url("~@/assets/images/curriculum/play.png") no-repeat 0px 0px;
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.active {
        color: @--color-primary;
        background: url("~@/assets/images/curriculum/play-1.png") no-repeat 0px
          0px;
      }
    }
  }
  .dol {
    font-size: 14px;
    .active {
      color: @--color-primary;
    }
  }
  .time {
    width: 350px;
    margin-left: 30px;
    display: inline-block;
  }
  .download {
    font-size: 14px;
    color: #5090ff;
    cursor: pointer;
    div:hover {
      text-decoration: underline;
    }
  }
  a.downloads {
    display: inline-block;
    padding-left: 20px;
    height: 16px;
    margin-left: 15px;
    background: url("~@/assets/images/curriculum/download.png") no-repeat 0px
      0px;
    cursor: pointer;
  }
  .ul-button {
    border: none;
    padding: 0 0 0 30px !important;
    &:active,
    &:focus,
    &:hover {
      background: #ffffff !important;
    }
  }
}
</style>
