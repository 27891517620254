<template>
  <div class="map-popup" v-if="mapVisible">
    <el-dialog
      :visible.sync="mapVisible"
      :before-close="beforClose"
      width="950px"
    >
      <baidu-map
        style="
          width: 100%;
          height: 500px;
          display: flex;
          flex-direction: column;
          margin-top: 15px;
        "
        ref="map"
        :zoom="mapZoom"
        :scroll-wheel-zoom="true"
        ak="scFpLNUqZM3XUKUUtmx3IfzLyNeljgg1"
        @ready="handlerBMap"
      >
        <!-- <bm-view style="flex: 1;display: none;"></bm-view> -->
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      </baidu-map>
    </el-dialog>
  </div>
</template>
<script>
import { BaiduMap, BmView, BmNavigation } from 'vue-baidu-map';
export default {
  name: 'Map',
  props: {
    mapVisible: {
      type: Boolean,
      default: false
    },
    lng: {
      type: String,
      default: ''
    },
    lat: {
      type: String,
      default: ''
    }
  },
  components: {
    BaiduMap,
    BmView,
    BmNavigation
  },
  data() {
    return {
      mapZoom: 13,
      BMap: '',
      map: ''
    };
  },
  created() {
    document.documentElement.scrollTop = 0;
  },
  methods: {
    handlerBMap() {
      const _this = this;
      _this.BMap = _this.$refs.map.BMap;
      _this.map = _this.$refs.map.map;
      var point;
      if (_this.lng || _this.lat) {
        point = new this.BMap.Point(_this.lng, _this.lat);
      } else {
        point = new this.BMap.Point(113.315809, 23.100454);
      }
      _this.map.centerAndZoom(point, 13);
      _this.map.addOverlay(new BMap.Marker(point));
    },
    // 关闭弹出窗口
    beforClose() {
      this.$emit('beforClose');
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.map-popup {
  box-sizing: border-box;
  color: @TEXT-COLOR-363636;
  .conet {
    margin-bottom: 1 5px;
  }
  /deep/.el-dialog__header {
    padding: 0;
  }
  /deep/.el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }
  /deep/.el-dialog__body {
    padding: 30px;
    box-sizing: border-box;
    color: @TEXT-COLOR-363636;
    height: auto;
    border-radius: 10px;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close:hover {
    color: @--color-primary;
  }
  /deep/.el-dialog__headerbtn /deep/.el-dialog__close {
    color: @TEXT-COLOR-363636;
  }
  /deep/.el-dialog__header {
    font-size: 20px;
    font-weight: bold;
  }
  /deep/.el-dialog__headerbtn {
    font-size: 20px;
    font-weight: bold;
  }
}
</style>